$('#lastname').keyup(function(event) {
	var textBox = event.target;
	var start = textBox.selectionStart;
	var end = textBox.selectionEnd;
	textBox.value = textBox.value.charAt(0).toUpperCase() + textBox.value.slice(1);
	textBox.setSelectionRange(start, end);
});

function getQueryString(name, url) {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, " "));
}

var id = getQueryString("id");
var lastname = getQueryString("lastname");

if(id){
	if (id.indexOf('&') > -1) {
		var queryStrings = id.split('&');
		var id = queryStrings[0];
		var lastname = queryStrings[1].replace("lastname=","");
		$("#lastname").val(lastname);	
	}
}

var data = { "affiliateId": 1108, "credentialId": id, "lastName": lastname };
var error1 = "We apologize, but something went wrong. Please try again.";
var error2 = "The system is not responding. Please try again later.";
var error3 = "Credentials not found. Please double check the information entered.";
var error4 = "Additional information is required. Please fill out the missing information in the form below.";

$("#id").val(id);
$("#lastname").val(lastname);

function showError(errormsg) {
	var content = errormsg;
	$('.alert').html("<p>" + content + "</p>");
}

function runMember() {
	$(this).find('button[type=submit]').click(function() {
		var theForm = $(this).closest('form.member');
		
		var fieldx = theForm.find('input[name=id]');
		var x = fieldx.val();
		//var re = new RegExp("^(4T91)-([0-9]){7}$");
		//x = re.test(x);
		//console.log(x);
		//if (x) {
		//	console.log("Valid");
		//} else {
		//	console.log("Invalid");
		//}
				
		if (x==null || x=="" || x=="Credential ID") {
			fieldx.addClass("error invalid");
			var flag = 1;
		} 
		
		//else if () {
		//	fieldx.addClass("error invalid");
		//	var flag = 2;
		//	console.log(flag);
		//}
		
		else {
			fieldx.removeClass("error invalid");
		}
		
		var fieldy = theForm.find('input[name=lastname]');
		var y = fieldy.val();
		if (y==null || y=="" || y=="Last Name") {
			fieldy.addClass("error invalid");
			var flag = 1;
		} else {
			fieldy.removeClass("error invalid");
		}
		
		if (flag==1) {
			alert("The highlighted field/s are required.");
			return false;
		} 
		//if (flag==2) {
		//	alert("Please check the formatting of ID No.");
		//	return false;
		//} 
		else {
			lastname = lastname.charAt(0).toUpperCase() + lastname.substr(1).toLowerCase();
			runMember();	
		}
	});

	if(id && lastname) {
		$('.section').html('<div class="center"><img src="/images/loader.gif" / ></div>');
		$.ajax({
			'url' : 'apiEndpoint_replaced_during_build',
			//'url' : 'https://prod-13.eastus.logic.azure.com:443/workflows/2595a6ecc2cd4e1fbebd8044b246aad8/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=iYnixjjMrnkk_uvKoHFIY4ghj2p8BGwEdb5ZW9pd28k',
			'headers' : {
				'Ocp-Apim-Subscription-Key' : '63a869818f73498cbfa3f6b98f77994d',
				'Content-Type': 'application/json'
			},							
			'type' : 'POST',
			'data' : JSON.stringify(data),
			success: function(data) {
				//console.log(data);
				//console.log("Status " + data.statusCode);
				if (data.statusCode == "404") {
					$('.section').html('<span class="warning"><i class="icon-close"></i> <span class="text">' + data.message + '</span></span><br/><p><a href="/sst/">&laquo; Try Again</a></p>');
				}
				else {
					var cardStatus = data.cardStatus.toLowerCase();
					//console.log("Card Status " + cardStatus);	
					
					var issueDate = data.issuanceDate;
					issueDate = issueDate.substring(0, 10);
					//console.log("Issued " + issueDate);

					//var expirationDate = data.expirationDate;
					//console.log("Expired " + expirationDate);		
					
					
					var expirationDate = data.expirationDate;
					//console.log("original expire format " + expirationDate);
					var expDateParts = expirationDate.split('/');
					var ExpDateString = new Date(Date.UTC(expDateParts[2], expDateParts[0] - 1, expDateParts[1], expDateParts[0])).toISOString().split('T')[0];
					//console.log("Expire formatted " + ExpDateString);
					
					if(cardStatus === "valid"){

						var date = new Date;
					    //console.log("Date " + date);
						var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
							.toISOString()
							.split("T")[0];
						//console.log("Today " + dateString);					

						if(ExpDateString < dateString){
							//console.log("in expired");
							$('.status .expired').css("display", "block");
						}
						else {
							//console.log("in verified");
							$('.status .verified').css("display", "block");

						}
					}
					else {
						$('.status .invalid').css("display", "block");
					}

					$('.section').html('<div class="section-card">\
						<div class="table_wrap">\
							<table class="backed">\
								<thead>\
									<tr>\
										<th colspan="2" scope="col">Employee Information</th>\
									</tr>\
								</thead>\
								<tbody>\
									<tr>\
										<td><strong>First name:</strong></td>\
										<td>' + data.firstName + '</td>\
									</tr>\
									<tr>\
										<td><strong>Middle Initial:</strong></td>\
										<td>' + data.middleName + '</td>\
									</tr>\
									<tr>\
										<td><strong>Last Name:</strong></td>\
										<td>' + data.lastName + '</td>\
									</tr>\
									<tr>\
										<td><strong>Suffix:</strong></td>\
										<td>' + data.suffix + '</td>\
									</tr>\
									<tr>\
										<td><strong>Height (inches):</strong></td>\
										<td>' + data.height + '"</td>\
									</tr>\
									<tr>\
										<td><strong>Eye Color:</strong></td>\
										<td>' + data.eyeColor + '</td>\
									</tr>\
									<tr>\
										<td><strong>Gender:</strong></td>\
										<td>' + data.gender + '</td>\
									</tr>\
								</tbody>\
							</table>\
						</div>\
					</div>\
					<div class="section-card">\
						<div class="table_wrap">\
							<table class="backed">\
								<thead>\
									<tr>\
										<th colspan="2" scope="col">Card Information</th>\
									</tr>\
								</thead>\
								<tbody>\
									<tr>\
										<td><strong>Type of SST ID:</strong></td>\
										<td>' + data.cardType + '</td>\
									</tr>\
									<tr>\
										<td><strong>Card Number:</strong></td>\
										<td>' + data.credentialId + '</td>\
									</tr>\
									<tr>\
										<td><strong>Issuance Date:</strong></td>\
										<td>' + issueDate + '</td>\
									</tr>\
									<tr>\
										<td><strong>Expiration Date:</strong></td>\
										<td>' + ExpDateString + '</td>\
									</tr>\
								</tbody>\
							</table>\
						</div>\
					</div>\
					<div class="section-card">\
						<div class="table_wrap">\
							<table class="backed">\
								<thead>\
									<tr>\
										<th colspan="2" scope="col">Training/Course</th>\
									</tr>\
								</thead>\
								<tbody>\
									<tr>\
										<td><strong>Type of Training:</strong></td>\
										<td>' + data.trainingType + '</td>\
									</tr>\
									<tr>\
										<td><strong>Number of Credit Hours Completed:</strong></td>\
										<td>' + data.creditHours + '</td>\
									</tr>\
								</tbody>\
							</table>\
						</div>\
					</div>\
					<p><a href="/sst/">&laquo; Verify another</a></p>');
				}

			},
			error: function(xhr) {
				var check = 1;
				//console.log(xhr.statusText);
				$('.member_form').css("display", "block");
				$('.alert').css("display", "block");
				if (xhr.status == "400" || xhr.status == "401" || xhr.status == "500" || xhr.status == "502") {
					showError(error2);
					$('.section').html('');
				}
				if(xhr.status == "404"){
					showError(error3);
					$('.section').html('');
				}
			}
		});
	}
	else {
		$('.section').css("display", "none");
		$('.member_form').css("display", "block");
		$('.alert').css("display", "block");
		showError(error4);
	}
}
$(document).ready(runMember);